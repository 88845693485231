import axios from "axios";
import lscache from "lscache";
import {getApiEndPoint} from "./utils";

export async function get(path, params) {
    try {
        const response = await axios.get(getApiEndPoint() + path, {
            withCredentials: true,
            headers: {
                "X-Requested-With": "XmlHttpRequest"
            },
            params: params
        });
        return response;
    } catch(e) {
        if (e.response && e.response.data && (e.response.data.errorType === "NotLoginError" || e.response.data.errorType === "LoginUserNotExists")) {
            removeSession();
        }
        throw e;
    }
}

export async function post(path, body) {
    try {
        const response = await axios.post(getApiEndPoint() + path, body, {
            withCredentials: true,
            headers: {
                "X-Requested-With": "XmlHttpRequest"
            }
        });
        if (response.data && response.data.sessionData)
        {
            lscache.set("sessionData", JSON.stringify(response.data.sessionData), response.data.sessionData.expireMinutes);
        }
        return response;
    } catch(e) {
        if (e.response && e.response.data && (e.response.data.errorType === "NotLoginError" || e.response.data.errorType === "LoginUserNotExists")) {
            removeSession();
        }
        throw e;
    }

}

export function removeSession() {
    lscache.remove("sessionData");
}

export function isLogin()
{
    lscache.flushExpired();
    return !!lscache.get("sessionData");
}
