import Vue from 'vue'
import VueRouter from 'vue-router'
import {isLogin} from "../common/api";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { title: "SES MEDIA｜IT・SES業界で働く方向け情報サイト", description: "SES MEDIA（エスイーエス メディア）はSES営業やフリーランスエージェント、SES業界で働く方向けにSES業界知識や営業ノウハウの他、首都圏を中心とした1,400社を超える国内最大規模のSES企業・フリーランスエージェントリストを公開・運営しております。" }
  },
  {
    path: '/companylist',
    name: 'CompanyList',
    component: () => import(/* webpackChunkName: "companyList" */ '../views/CompanyList.vue'),
    meta: { title: "SES MEDIA｜SES企業・フリーランスエージェントリスト", description: "国内最大規模1,400社超のSES企業・フリーランスエージェントの情報を掲載しております。企業名はもちろん、資本金、従業員数、取得免許有無など企業の詳細な情報まで掲載しております。協力会社探しにお役立てください。" }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
    meta: { title: "SES MEDIA｜プライバシーポリシー", description: "SES MEDIAのプライバシーポリシーページです。会員登録、記事の閲覧、企業情報の登録・更新・削除をされる際にはご一読ください。" }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: { title: "SES MEDIA｜利用規約", description: "SES MEDIAの利用規約ページです。会員登録、記事の閲覧、企業情報の登録・更新・削除をされる際にはご一読ください。" }
  },
  {
    path: '/article',
    name: 'Article',
    component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
    meta: { title: "SES MEDIA｜コンテンツ一覧", description: "IT・SES業界で働く方向けのコンテンツ一覧ページです。契約・働き方・キャリア・お金などの役立つノウハウをお届けしています。" }
  },
  {
    path: '/article/:englishTitle',
    name: 'ArticleDetail',
    component: () => import(/* webpackChunkName: "articleDetail" */ '../views/ArticleDetail.vue'),
    meta: { title: "SES MEDIA", description: "" }
  },
  // {
  //   path: '/interview',
  //   name: 'Interview',
  //   component: () => import(/* webpackChunkName: "article" */ '../views/Interview.vue'),
  //   meta: { title: "SES MEDIA｜インタビュー記事一覧", description: "IT・SES業界で働く方向けのインタビュー記事一覧ページです。" }
  // },
  // {
  //   path: '/interview/:englishTitle',
  //   name: 'InterviewDetail',
  //   component: () => import(/* webpackChunkName: "articleDetail" */ '../views/InterviewDetail.vue'),
  //   meta: { title: "SES MEDIA", description: "" }
  // },
  {
    path: '/register',
    name: 'RegisterAccount',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/RegisterAccount.vue'),
    meta: { title: "SES MEDIA｜アカウント登録", description: "SES MEDIAのアカウント登録ページです。メールアドレスから簡単に登録を行えます。" }
  },
  {
    path: '/registerform',
    name: 'RegisterForm',
    component: () => import(/* webpackChunkName: "userRegister" */ '../views/auth/RegisterForm.vue'),
    meta: { title: "SES MEDIA｜アカウント登録フォーム", description: "SES MEDIAのアカウント登録フォームです。アカウント情報の入力を行ってください。" }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
    meta: { title: "SES MEDIA｜ログイン", description: "" }
  },
  {
    path: '/requestreissue',
    name: 'RequestPasswordReissue',
    component: () => import(/* webpackChunkName: "requestPasswordReissue" */ '../views/auth/RequestPasswordReissue.vue'),
    meta: { title: "SES MEDIA｜パスワード再発行申請", description: "" }
  },
  {
    path: '/passwordreissue',
    name: 'PasswordReissue',
    component: () => import(/* webpackChunkName: "passwordReissue" */ '../views/auth/PasswordReissue.vue'),
    meta: { title: "SES MEDIA｜パスワード再発行", description: "" }
  },
  {
    path: '/mypage',
    name: 'MyPage',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MyPage.vue'),
    meta: { title: "SES MEDIA｜マイページ", description: "", isRequireLogin: true }
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "changePassword" */ '../views/mypage/ChangePassword.vue'),
    meta: { title: "SES MEDIA｜パスワード変更", description: "", isRequireLogin: true }
  },
  {
    path: '/changename',
    name: 'ChangeName',
    component: () => import(/* webpackChunkName: "changeName" */ '../views/mypage/ChangeName.vue'),
    meta: { title: "SES MEDIA｜ユーザー名変更", description: "", isRequireLogin: true }
  },
  {
    path: '/registercompany',
    name: 'RegisterCompany',
    component: () => import(/* webpackChunkName: "registerCompany" */ '../views/company/RegisterCompany.vue'),
    meta: { title: "SES MEDIA｜企業登録", description: "", isRequireLogin: true }
  },
  // {
  //   path: '/managecompany',
  //   name: 'ManageCompany',
  //   component: () => import(/* webpackChunkName: "editCompany" */ '../views/company/MyPage.vue'),
  //   meta: { title: "SES MEDIA｜企業管理", description: "", isRequireLogin: true }
  // },
  {
    path: '/editcompany',
    name: 'EditCompany',
    component: () => import(/* webpackChunkName: "editCompany" */ '../views/company/EditCompany.vue'),
    meta: { title: "SES MEDIA｜企業編集", description: "", isRequireLogin: true }
  },
  {
    path: '/userinvitation',
    name: 'UserInvitation',
    component: () => import(/* webpackChunkName: "linkCompany" */ '../views/company/UserInvitation.vue'),
    meta: { title: "SES MEDIA｜ユーザー招待", description: "", isRequireLogin: true }
  },
  {
    path: '/joingroup',
    name: 'JoinGroup',
    component: () => import(/* webpackChunkName: "linkCompany" */ '../views/company/JoinGroup.vue'),
    meta: { title: "SES MEDIA｜企業グループ参加", description: "" }
  },
  {
    path: '/linklistedcompany',
    name: 'LinkListedCompany',
    component: () => import(/* webpackChunkName: "linkListedCompany" */ '../views/company/LinkListedCompany.vue'),
    meta: { title: "SES MEDIA｜掲載済み企業と紐づけ", description: "", isRequireLogin: true }
  },
  {
    path: '/linkcompany',
    name: 'LinkCompany',
    component: () => import(/* webpackChunkName: "linkCompany" */ '../views/company/manager/LinkCompany.vue'),
    meta: { title: "SES MEDIA｜企業紐づけ", description: "", isRequireLogin: true }
  },
  {
    path: '/userexpulsion',
    name: 'UserExpulsion',
    component: () => import(/* webpackChunkName: "userExpulsion" */ '../views/company/UserExpulsion.vue'),
    meta: { title: "SES MEDIA｜企業紐付け解除", description: "", isRequireLogin: true }
  },
  {
    path: '/jobinfo',
    name: 'JobInfo',
    component: () => import(/* webpackChunkName: "jobInfo" */ '../views/jobinfo/JobInfo.vue'),
    meta: { title: "SES MEDIA｜求人管理", description: "", isRequireLogin: true }
  },
  {
    path: '/registerjobinfo',
    name: 'RegisterJobInfo',
    component: () => import(/* webpackChunkName: "registerJobInfo" */ '../views/jobinfo/RegisterJobInfo.vue'),
    meta: { title: "SES MEDIA｜求人情報登録", description: "", isRequireLogin: true }
  },
  {
    path: '/editjobinfo',
    name: 'EditJobInfo',
    component: () => import(/* webpackChunkName: "editJobInfo" */ '../views/jobinfo/EditJobInfo.vue'),
    meta: { title: "SES MEDIA｜求人情報更新", description: "", isRequireLogin: true }
  },
  {
    path: '/jobinfodetail',
    name: 'JobInfoDetail',
    component: () => import(/* webpackChunkName: "jobinfodetail" */ '../views/jobinfo/JobInfoDetail.vue'),
    meta: { title: "SES MEDIA｜求人詳細", description: "" }
  },
  {
    path: '/jobcontact',
    name: 'JobContactForm',
    component: () => import(/* webpackChunkName: "editJobInfo" */ '../views/jobinfo/JobContactForm.vue'),
    meta: { title: "SES MEDIA｜求人応募", description: "" }
  },
  {
    path: '/:path/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
    props: true,
    meta: { title: "SES MEDIA｜エラー", description: "" }
  },
  {
    path: '/:path/result',
    name: 'Result',
    component: () => import(/* webpackChunkName: "registerCompany" */ '../views/Result.vue'),
    props: true,
    meta: { title: "SES MEDIA", description: "" }
  },
  /**404確認用**/
  {
    path: '/*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: { title: "SES MEDIA｜ページが見つかりません", description: "" }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.path !== from.path) {
      return { x: 0, y: 0 }
    }
  }
});
router.beforeEach((to, from, next) => {
  document.body.removeAttribute("class");
  if (to.matched.some(record => record.meta.isRequireLogin)) {
    if (!isLogin()) {
      next({ name: 'Login' })
    }
  }
  next()
})
router.afterEach((to) => {
  if (to.meta) {
    document.title = to.meta.title;
    document.querySelector('meta[name="description"]').setAttribute("content", to.meta.description);
  }
});
export default router
