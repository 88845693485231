import CryptoJS from "crypto-js";

export function encryptKey(key) {
    const bytes = new TextEncoder().encode(key);
    for (let i = 0; i < bytes.length; i++) {
        if (i % 2 === 0) {
            bytes[i] = ~bytes[i];
        }
    }

    const index1 = getRandomRangeInt(0, Math.floor(bytes.length * 0.5));
    const index2 = getRandomRangeInt(index1 + 1, bytes.length - 1);
    const keys = [
        bytes.slice(index2, bytes.length),
        bytes.slice(0, index1),
        bytes.slice(index1, index2),
    ]
    console.log(keys);
}

export function decryptKey(keyBytesList) {
    const keyBytes = Uint8Array.from([...keyBytesList[1], ...keyBytesList[2], ...keyBytesList[0]]);
    for (let i = 0; i < keyBytes.length; i++) {
        if (i % 2 === 0) {
            keyBytes[i] = ~keyBytes[i];
        }
    }
    return new TextDecoder().decode(keyBytes);
}

export function encrypt(text, key) {
    encryptKey(key);
}

export function decrypt(text, keyBytes) {
    const bytes = CryptoJS.AES.decrypt(text, decryptKey(keyBytes));
    return bytes.toString(CryptoJS.enc.Utf8);
}

export function getRandomRangeInt(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

export function isDevelop() {
    return location.hostname.includes("dev") || location.hostname.includes("localhost");
}

export function getApiEndPoint() {
    return process.env.VUE_APP_API_ENDPOINT;
}
